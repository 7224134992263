/*
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by Xelorian Ltd

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Xelorian React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

function ButtonsSizes() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <MKButton color="info" size="small">
              small
            </MKButton>
            <MKButton color="info">default</MKButton>
            <MKButton color="info" size="large">
              large
            </MKButton>
          </Stack>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ButtonsSizes;
