/**
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by Xelorian Ltd

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Xelorian React base styles
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

const { borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};
