import React, { useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import Card from "@mui/material/Card";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useAuth } from "../../../context/AuthContext";
import XeloLayout from "layouts/sections/components/XeloLayout";

function UserProfile() {
  const { user } = useAuth(); // Replace with actual user data from AuthContext
  const [emailVerified, setEmailVerified] = useState(false);
  const [email, setEmail] = useState(user?.email || "");
  const [mobileNumber, setMobileNumber] = useState(user?.mobileNumber || "");

  const verifyEmail = () => {
    // Simulate email verification action
    setEmailVerified(true);
  };

  return (
    <XeloLayout
    title="User Profile"
     // Function to update selected tab
  >
   
  
    <MKBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
      p={3}
    >
      <Card
        sx={{
          width: "100%",
          maxWidth: "600px",
          padding: "24px",
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
        }}
      >
        <MKTypography variant="h4" gutterBottom textAlign="center">
          User Profile
        </MKTypography>
        <MKBox mt={2}>
          {/* Username - Readonly */}
          <MKTypography variant="body2" color="textSecondary">
            Username
          </MKTypography>
          <MKInput
            value={user?.username || "Unknown User"}
            fullWidth
            readOnly
            sx={{ backgroundColor: "#f8fafc" }}
          />
        </MKBox>

        <MKBox mt={3}>
          {/* Email - Editable with Verify Button */}
          <MKTypography variant="body2" color="textSecondary">
            Email Address
          </MKTypography>
          <MKBox display="flex" alignItems="center" gap={2}>
            <MKInput
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            {emailVerified ? (
              <VerifiedIcon sx={{ color: "#42A5F5" }} />
            ) : (
              <MKButton variant="contained" color="info" onClick={verifyEmail}>
                Verify
              </MKButton>
            )}
          </MKBox>
        </MKBox>

        <MKBox mt={3}>
          {/* Mobile Number - Editable */}
          <MKTypography variant="body2" color="textSecondary">
            Mobile Number
          </MKTypography>
          <MKInput
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            fullWidth
          />
        </MKBox>

        {/* Additional Fields */}
        <MKBox mt={3}>
          <MKTypography variant="body2" color="textSecondary">
            Additional Information
          </MKTypography>
          <MKInput placeholder="Enter additional details..." fullWidth />
        </MKBox>

        {/* Save Button */}
        <MKBox mt={4} textAlign="center">
          <MKButton variant="contained" color="primary">
            Save Changes
          </MKButton>
        </MKBox>
      </Card>
    </MKBox>
    </XeloLayout>
  );
}

export default UserProfile;
