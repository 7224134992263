import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";

// Xelorian React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";

// Material-UI components
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";

// Icons
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SensorsIcon from "@mui/icons-material/Sensors";
import AppsIcon from "@mui/icons-material/Apps";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";

// Xelo Pages
import XeloGuard from "pages/XeloPages/XeloGuard";
import XeloVerify from "pages/XeloPages/XeloVerify";
import XeloSense from "pages/XeloPages/XeloSense";
import XeloStudio from "pages/XeloPages/XeloStudio";
import XeloChat from "pages/XeloPages/XeloChat";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";

import routes from "routes";

// Import logo
import logo from "assets/images/logo-ct-dark.png";
import UserProfile from "pages/UserPages/UserProfile";
import SignOut from "pages/LandingPages/SignOut";

function XeloLayout({ title, children }) {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [selectedTab, setSelectedTab] = useState("XeloGuard");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const navigationItems = [
    { label: "XeloGuard", icon: <SecurityIcon />, component: <XeloGuard /> },
    { label: "XeloVerify", icon: <VerifiedUserIcon />, component: <XeloVerify /> },
    { label: "XeloSense", icon: <SensorsIcon />, component: <XeloSense /> },
    { label: "XeloStudio", icon: <AppsIcon />, component: <XeloStudio /> },
    { label: "XeloChat", icon: <VoiceChatIcon />, component: <XeloChat /> },
  ];

  // Combine routes for the DefaultNavbar
  const combinedRoutes = [
    ...routes, // Existing routes
    {
      name: "Account",
      icon: <AccountCircleIcon />,
      dropdown: true,
      collapse: [
        {
          name: "profile",
          icon: <AccountBoxIcon />,
          route: "/pages/userpages/profile",
          component:<UserProfile/>,
        },
        {
          name: "settings",
          icon: <SettingsIcon />,
          route: "/sections/input-areas/forms",
        },
        {
          name: "sign out",
          icon: <ExitToAppIcon />,
          route: "/pages/authentication/signout",
          component:<SignOut/>,
        },
      ],
    }
  ];

  useEffect(() => {
    if (!token) {
      // navigate("/pages/authentication/sign-in");
    }
  }, [token, navigate]);

  const currentTabComponent = navigationItems.find(
    (item) => item.label === selectedTab
  )?.component;

  return (
    <MKBox display="flex" flexDirection="column" bgColor="#FFFFFF" minHeight="100vh">
      {/* AppBar with Toolbar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#FFFFFF", // White background
          transition: "width 0.3s ease-in-out",
          width: "100%", // Always full width
          left: 0,
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            borderBottom: "1px solid #E0E0E0", // Subtle border
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          {/* Logo or Branding */}
         

          {/* Menu Items in Toolbar */}
          <MKBox display="flex" alignItems="center">
            <DefaultNavbar routes={combinedRoutes} transparent relative />
          </MKBox>

          {/* Menu Button (Fixed in Top-Right Corner) */}
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              backgroundColor: "#1A73E8", // Blue background
              color: "#FFFFFF", // White icon
              boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
              transition: "background 0.3s ease, transform 0.2s ease",
              "&:hover": {
                backgroundColor: "#42A5F5", // Lighter blue on hover
                transform: "scale(1.1)", // Slight hover effect
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Main Layout */}
      <MKBox display="flex" flexDirection="row" width="100%" flexGrow={1} mt={8}>
        {/* Content Area */}
        <MKBox
          flex={1}
          p={3}
          sx={{
            background: "#FFFFFF", // White background
            color: "#202124", // Dark gray text
            borderRadius: "12px",
            margin: "16px",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)", // Subtle shadow
            transition: "margin-right 0.3s ease-in-out",
            marginRight: drawerOpen ? "20px" : "20px", // Adjusted to match drawer width
            minHeight: "calc(100vh - 64px - 64px)", // Fit to window height (subtract header and footer height)
            flexGrow: 1, // Allow content area to expand
            overflowY: "auto", // Add scroll if content overflows
          }}
        >
          <MKBox
            mb={3}
            p={3}
            sx={{
              background: "#F5F5F5", // Light gray background for the header
              borderRadius: "8px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)", // Subtle shadow
              color: "#202124", // Dark gray text
            }}
          >
            <Breadcrumbs
              routes={[{ label: selectedTab, href: `#${selectedTab}` }]}
              sx={{
                "& a": {
                  color: "#1A73E8", // Blue links
                },
                mt: 1,
                height: "10px",
                display: "flex",
                alignItems: "center",
              }}
            />
            <MKTypography variant="h3" mb={2} color="#1A73E8" fontWeight="bold">
              {title || selectedTab}
            </MKTypography>
          </MKBox>

          {/* Render selected tab or fallback children */}
          {children || currentTabComponent}
        </MKBox>

        {/* Sidebar */}
        <Drawer
          variant="permanent"
          anchor="right"
          sx={{
            width: drawerOpen ? 300 : 60,
            flexShrink: 0,
            transition: "width 0.3s ease-in-out",
            "& .MuiDrawer-paper": {
              width: drawerOpen ? 300 : 60,
              boxSizing: "border-box",
              background: "#F5F5F5", // Light gray background
              color: "#202124", // Dark gray text
              padding: drawerOpen ? "20px 10px" : "10px 0",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
              borderTopLeftRadius: "15px",
              borderBottomLeftRadius: "15px",
              transition: "width 0.3s ease-in-out, padding 0.3s ease-in-out",
              overflowX: "hidden", // Prevent horizontal scroll
              height: "calc(100vh - 64px)", // Match content area height
              top: "80px", // Position below the toolbar
            },
          }}
          open
        >
          <MKBox display="flex" flexDirection="column" mt={15} width="100%">
            {navigationItems.map(({ label, icon }) => (
              <Card
                key={label}
                onClick={() => setSelectedTab(label)}
                sx={{
                  display: "flex",
                  flexDirection: drawerOpen ? "row" : "column",
                  alignItems: "center",
                  cursor: "pointer",
                  marginBottom: "12px",
                  padding: drawerOpen ? "14px" : "10px",
                  background: label === selectedTab ? "#1A73E8" : "#FFFFFF", // White background, blue for selected
                  color: label === selectedTab ? "#FFFFFF" : "#202124", // White text for selected, dark gray for normal
                  borderRadius: "12px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)", // Subtle shadow
                  transition: "background 0.3s ease, color 0.3s ease, transform 0.2s ease",
                  "&:hover": {
                    backgroundColor: label === selectedTab ? "#1A73E8" : "#E3F2FD", // Light blue on hover
                    transform: "translateX(5px)", // Slight hover effect
                  },
                }}
              >
                {icon}
                {drawerOpen && (
                  <MKTypography
                    variant="body2"
                    ml={2}
                    color="inherit"
                    fontWeight={label === selectedTab ? "bold" : "normal"}
                  >
                    {label}
                  </MKTypography>
                )}
              </Card>
            ))}
          </MKBox>
        </Drawer>
      </MKBox>

      {/* Footer */}
      <CenteredFooter />
    </MKBox>
  );
}

export default XeloLayout;