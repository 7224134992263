import React, { useState, useEffect } from "react";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext"; // Adjust path as needed
import { Box, Button } from "@mui/material"; // Material-UI components

function SignOut() {
  const navigate = useNavigate();
  const { logout } = useAuth(); // Get logout function from AuthContext
  const [showModal, setShowModal] = useState(true); // Modal shows as soon as the component loads
  const [logoutError, setLogoutError] = useState(null); // Manage logout errors

  // Logic for handling the sign-out confirmation
  const handleSignOut = async () => {
    try {
      await logout(); // Call the logout function from AuthContext
      navigate("/pages/authentication/sign-in"); // Redirect to sign-in page after successful logout
    } catch (err) {
      // If there's an error during logout
      setLogoutError("Failed to log out, please try again.");
    }
  };

  const handleConfirmation = (confirm) => {
    if (confirm) {
      handleSignOut(); // Proceed with sign-out
    } else {
      setShowModal(false); // Close the modal if the user cancels
    }
  };

  // Trigger the modal to show on load using useEffect
  useEffect(() => {
    setShowModal(true); // Ensure the modal is visible as soon as the component mounts
  }, []);

  return (
    <div>
      {showModal && (
        // Display the confirmation modal overlay if showModal is true
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0,0,0,0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999, // Ensure modal is above other elements
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: 3,
              borderRadius: 2,
              boxShadow: 3,
              minWidth: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <MKTypography variant="h5" color="text.primary" mb={2}>
              Are you sure you want to sign out?
            </MKTypography>
            {logoutError && (
              <MKTypography variant="body2" color="error.main">
                {logoutError}
              </MKTypography>
            )}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="contained" color="error" onClick={() => handleConfirmation(true)}>
                Yes, Sign Out
              </Button>
              <Button variant="outlined" color="primary" onClick={() => handleConfirmation(false)}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
}

export default SignOut;
