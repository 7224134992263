/**
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by Xelorian Ltd

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The linearGradient() function helps you to create a linear gradient color background
 */

function linearGradient(color, colorState, angle = 195) {
  return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

export default linearGradient;
