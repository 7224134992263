import React, { createContext, useState, useContext, useEffect } from "react";

// Create a context for the token
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

// Provide the context in the application
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token")); // Persist token in local storage
  const [error, setError] = useState(null);

  // Use effect to load the token from local storage on initial render
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token); // Persist token on page reload
    } else {
      localStorage.removeItem("token"); // Remove token on logout
    }
  }, [token]);

  // Login function that communicates with the backend API
  const login = async (username, password) => {
    setError(null); // Reset previous errors

    try {
      const response = await fetch(
        "https://srv581460.hstgr.cloud/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ Username: username, Password: password }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Invalid login credentials");
      }

      const data = await response.json();
      const { token } = data;
      setToken(token); // Store token in state
      return data; // Return response data for further use (optional)
    } catch (err) {
      setError(err.message); // Set error message if any
      throw err; // Re-throw error to be handled in the component
    }
  };

  // // Logout function to clear the token
  // const logout = () => {
  //   setToken(null); // Remove the token from state
  //   localStorage.removeItem("token"); // Clear token from localStorage
  // };
  // Inside your AuthProvider
  const logout = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage or state
  
      // Send logout request to server "https://srv581460.hstgr.cloud/api/auth/login"
      await fetch("https://srv581460.hstgr.cloud/api/auth/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token in the Authorization header
        },
        credentials: "include", // To send cookies, if any
      });
  
      // After successful logout from the server, clear local state and storage
      setToken(null); // Clear token from state
      localStorage.removeItem("token"); // Clear token from local storage
  
      console.log("Logout successful.");
    } catch (error) {
      console.error("Logout failed:", error);
      setError("Logout failed. Please try again.");
    }
  };
  

  // Value to be provided via context
  const value = {
    token,
    error,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
