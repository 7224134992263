import React, { useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const DocumentVerification = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileSubmit = async () => {
    if (!selectedFile) return;
    
    const formData = new FormData();
    formData.append("file", selectedFile);

    // Call backend API for document verification
    // Example:
    const response = await fetch("/api/verify-document", {
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (result.success) {
      alert("Document Verified!");
    } else {
      alert("Verification Failed");
    }
  };

  return (
    <MKBox p={3}>
      <MKTypography variant="h4">Document Verification</MKTypography>
      <MKTypography variant="body1" mt={2}>
        Upload your document for verification.
      </MKTypography>

      <Input
        accept=".jpg, .jpeg, .png, .pdf"
        type="file"
        onChange={handleFileChange}
        inputProps={{ 'aria-label': 'Upload Document' }}
        sx={{ marginTop: "20px" }}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleFileSubmit}
        sx={{ marginTop: 2 }}
        startIcon={<CloudUploadIcon />}
      >
        Submit for Verification
      </Button>
    </MKBox>
  );
};

export default DocumentVerification;
