/**
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by Xelorian Ltd

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Xelorian React Base Styles
import colors from "assets/theme/base/colors";

const { transparent } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
    },
  },
};
