/*
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by Xelorian Ltd

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Xelorian React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/Xelorian/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            {/* Built by developers */}
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
          Success Story: Overcoming Challenges to Inspire Innovation          </MKTypography>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
          At Xelorian Ltd, our journey has been one of resilience and adaptability. When we first embarked on building our tech consulting and solutions platform, we faced numerous hurdles — from navigating a competitive industry to balancing resources as a small, passionate team. However, instead of viewing these challenges as setbacks, we saw them as opportunities to innovate.
          </MKTypography>
          <MKTypography
            component="a"
            href="https://www.xelorian.com/learning-lab/react/overview/material-kit/"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            Read docs <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
