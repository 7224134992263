import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Importing icons
import SecurityIcon from "@mui/icons-material/Security";
import ShieldIcon from "@mui/icons-material/Shield";
import GppGoodIcon from "@mui/icons-material/GppGood";
import LockIcon from "@mui/icons-material/Lock";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";

const XeloGuard = () => {
  const details = [
    {
      label: "Real-time threat monitoring",
      description: "Monitor and analyze threats in real time.",
      icon: <SecurityIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "User activity encryption",
      description: "Ensure end-to-end encryption for user activities.",
      icon: <ShieldIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Incident response tools",
      description: "Quickly identify and respond to incidents.",
      icon: <GppGoodIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Privacy compliance integration",
      description: "Seamless integration with compliance standards.",
      icon: <PrivacyTipIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Enhanced security layers",
      description: "Add additional security layers for robust protection.",
      icon: <LockIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
  ];

  return (
    <MKBox>
      <MKTypography variant="h4" mb={3}>
      XeloGuard – Privacy-Preserving Security
      </MKTypography>

      <Grid container spacing={3}>
        {details.map((detail, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                minHeight: 180,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#1976D2", // Blue color
                color: "white", // White text and icons
                textAlign: "center",
                padding: 2,
                borderRadius: "12px", // Smooth corners
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Slight shadow
                transition: "transform 0.2s ease-in-out, background-color 0.2s ease",
                "&:hover": {
                  backgroundColor: "#1565C0", // Darker blue shade
                  transform: "scale(1.05)", // Slight zoom on hover
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Icon for each feature */}
                {detail.icon}

                <MKTypography variant="h6" color="white" gutterBottom>
                  {detail.label}
                </MKTypography>
                <MKTypography variant="body2" color="white">
                  {detail.description}
                </MKTypography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MKBox>
  );
};

export default XeloGuard;
