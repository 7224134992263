

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Xelorian React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Xelorian React routes
import routes from "routes";

import Dashboard from "pages/Dashboard";
import XeloGuard from "pages/XeloPages/XeloGuard";
import XeloVerify from "pages/XeloPages/XeloVerify";
import XeloSense from "pages/XeloPages/XeloSense";
import XeloStudio from "pages/XeloPages/XeloStudio";
import SignInBasic from "pages/LandingPages/SignIn";
import SignUp from "pages/LandingPages/SignUp";
import SignOut from "pages/LandingPages/SignOut";
import UserProfile from "pages/UserPages/UserProfile";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="//pages/authentication/sign-in" element={<SignInBasic />} />
        <Route path="/pages/authentication/sign-up" element={<SignUp/>}/>
        <Route path="/pages/authentication/signout" element={<SignOut/>}/>
        <Route path="/pages/userpages/profile" element={<UserProfile/>}/>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/xelo-guard" element={<XeloGuard />} />
        <Route path="/xelo-verify" element={<XeloVerify />} />
        <Route path="/xelo-sense" element={<XeloSense />} />
        <Route path="/xelo-studio" element={<XeloStudio />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
    </ThemeProvider>
  );
}
