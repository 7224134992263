import React, { useState } from "react";
import { CSSTransition } from "react-transition-group"; // For animations

// Sections components
import XeloLayout from "layouts/sections/components/XeloLayout";  // Use the new XeloLayout

// Xelorian React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material-UI components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Icons
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SensorsIcon from "@mui/icons-material/Sensors";
import AppsIcon from "@mui/icons-material/Apps";

// Individual components
import XeloGuard from "pages/XeloPages/XeloGuard";
import XeloVerify from "pages/XeloPages/XeloVerify";
import XeloSense from "pages/XeloPages/XeloSense";
import XeloStudio from "pages/XeloPages/XeloStudio";
import { Drawer } from "@mui/material";

function Dashboard() {
  const [isTileView, setTileView] = useState(true); // State for tile view mode
  const [selectedTab, setSelectedTab] = useState("XeloGuard"); // Track selected tab (initially "XeloGuard")
  const [drawerOpen, setDrawerOpen] = useState(true); // Track sidebar visibility

  // Define navigation items
  const navigationItems = [
    {
      label: "XeloGuard",
      description: "Privacy-preserving security solutions that prioritize user safety.",
      icon: <SecurityIcon fontSize="large" color="primary" />,
      component: <XeloGuard />,
    },
    {
      label: "XeloVerify",
      description: "Advanced verification mechanisms to ensure authenticity and streamline processes.",
      icon: <VerifiedUserIcon fontSize="large" color="primary" />,
      component: <XeloVerify />,
    },
    {
      label: "XeloSense",
      description: "AI-driven anomaly detection to adapt to threats in real-time.",
      icon: <SensorsIcon fontSize="large" color="primary" />,
      component: <XeloSense />,
    },
    {
      label: "XeloStudio",
      description: "A centralized management platform for Xelo services.",
      icon: <AppsIcon fontSize="large" color="primary" />,
      component: <XeloStudio />,
    },
  ];

  // Handle card click: Transition from tiles to sidebar view
  const handleCardClick = (label) => {
    setSelectedTab(label);
    setTileView(false); // Switch to sidebar view
  };

  // Breadcrumbs based on selected tab
  const breadcrumbs = [
    { label: "Home", route: "/" },
    { label: "Dashboard" },
    !isTileView && { label: selectedTab },
  ].filter(Boolean); // Remove any falsy values (e.g., if tileView is true)

  return (
    <XeloLayout
      title="Dashboard"
      breadcrumb={breadcrumbs}
      drawerOpen={drawerOpen}
      toggleDrawer={() => setDrawerOpen(!drawerOpen)} // Function to toggle sidebar visibility
      onTabChange={setSelectedTab} // Function to update selected tab
    >
     
    </XeloLayout>
  );
}

export default Dashboard;
