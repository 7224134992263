import React, { useState } from "react";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, MenuItem, Select, InputLabel, FormControl, CircularProgress } from "@mui/material";

const XeloChat = () => {
  const [userInput, setUserInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [selectedModel, setSelectedModel] = useState("gemma"); // Default model

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFilePreview(URL.createObjectURL(uploadedFile)); // Generate a preview URL for the image
    }
  };

  const removeSelectedFile = () => {
    setFile(null);
    setFilePreview(null);
  };

  // Function to format the LLM response (e.g., markdown or JSON)
  const formatResponse = (response) => {
    try {
      // Check if the response is JSON
      const parsedResponse = JSON.parse(response);
      return (
        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {JSON.stringify(parsedResponse, null, 2)}
        </pre>
      );
    } catch (error) {
      // If not JSON, treat it as plain text or markdown
      return response.split("\n").map((line, index) => (
        <p key={index} style={{ margin: "4px 0" }}>
          {line}
        </p>
      ));
    }
  };

  const handleSend = async () => {
    const trimmedInput = userInput.trim();
    if (!trimmedInput && !file) return;

    const newChat = [...chatLog];

    if (trimmedInput) {
      newChat.push({ user: true, message: trimmedInput });
    }

    if (file && filePreview) {
      newChat.push({
        user: true,
        message: (
          <div style={{ position: "relative" }}>
            <img
              src={filePreview}
              alt="Uploaded"
              style={{ maxWidth: "200px", borderRadius: "8px" }}
            />
            <IconButton
              onClick={removeSelectedFile}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                background: "#fff",
                borderRadius: "50%",
                padding: "5px",
              }}
            >
              <ClearIcon style={{ color: "#f00" }} />
            </IconButton>
          </div>
        ),
      });
    }

    setChatLog(newChat);
    setUserInput("");
    setFile(null);
    setFilePreview(null);
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("prompt", trimmedInput);
      formData.append("model_type", selectedModel);
      if (file) {
        formData.append("image", file);
      }

      const apiEndpoint = "https://srv581460.hstgr.cloud/api/llm/chat"; // Updated API endpoint

      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`HTTP error! status: ${response.status} - ${errorMessage}`);
      }

      const data = await response.json();
      setChatLog([...newChat, { user: false, message: formatResponse(data.response) }]);
    } catch (error) {
      console.error(error);
      setChatLog([...newChat, { user: false, message: "Error: Something went wrong." }]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !loading) {
      handleSend();
    }
  };

  return (
    <MKBox py={5} px={3} sx={{ backgroundColor: "#F4F6F8", minHeight: "100vh" }}>
      <MKTypography variant="h4" mb={4} align="center" color="primary" fontWeight="bold">
        XeloChat - GenAI Playground
      </MKTypography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <FormControl fullWidth>
            <InputLabel>Select Model</InputLabel>
            <Select
              value={selectedModel}
              onChange={(e) => setSelectedModel(e.target.value)}
              label="Select Model"
            >
              <MenuItem value="meta">Meta</MenuItem>
              <MenuItem value="gemma">Gemma</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={10} lg={8}>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              height: 600,
              borderRadius: "12px",
              overflow: "hidden",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
            }}
            elevation={4}
          >
            <Box
              sx={{
                flex: 1,
                overflowY: "scroll",
                padding: 2,
                backgroundColor: "#FFFFFF",
              }}
            >
              {chatLog.map((chat, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: chat.user ? "flex-end" : "flex-start",
                    my: 1,
                  }}
                >
                  <MKBox
                    sx={{
                      maxWidth: "75%",
                      padding: 1.5,
                      backgroundColor: chat.user ? "#1976D2" : "#E3F2FD",
                      color: chat.user ? "#fff" : "#000",
                      borderRadius: "12px",
                      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    {typeof chat.message === "string" ? chat.message : chat.message}
                  </MKBox>
                </Box>
              ))}
              {loading && (
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <CircularProgress size={24} />
                  <MKTypography variant="body2" color="textSecondary" mt={1}>
                    Thinking...
                  </MKTypography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 2,
                borderTop: "1px solid #ddd",
                backgroundColor: "#F9FAFB",
              }}
            >
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Type your query here..."
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ borderRadius: "8px" }}
                disabled={loading}
              />
              <IconButton color="primary" component="label" sx={{ ml: 1 }} disabled={loading}>
                <AttachFileIcon />
                <input type="file" hidden onChange={handleFileUpload} />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleSend}
                disabled={(!userInput.trim() && !file) || loading}
                sx={{ ml: 1 }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </MKBox>
  );
};

export default XeloChat;