import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Xelorian React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Xelorian React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";
import Breadcrumbs from "examples/Breadcrumbs";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MKButton from "components/MKButton";// Routes
import routes from "routes";
import SignIn from "layouts/pages/authentication/sign-in";
import { useAuth } from "context/AuthContext"; // Ensure the correct import path

function BaseLayout({ breadcrumb, title, children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication status
  const { user, login, logout } = useAuth(); // Access auth context (assuming a login and logout function exists)

  useEffect(() => {
    // Update authentication state when the auth context updates
    setIsAuthenticated(!!user); // Assuming 'user' will be null or undefined if not logged in
  }, [user]);
  const navigate = useNavigate();
  // Handle sign in/out
  const handleAuthAction = () => {
    if (isAuthenticated) {
      logout(); // Call logout if the user is logged in
    } else {
      // Redirect to sign-in page if user is not logged in
      //window.location.href = "/pages/authentication/sign-in";
      navigate("/pages/authentication/sign-in");
    }
  };

  return (
    <MKBox display="flex" flexDirection="column" bgColor="white" minHeight="100vh">
      <MKBox bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar
          routes={routes}
          action={{
            label: isAuthenticated ? "Sign Out" : "Sign In", // Toggle the action based on auth status
            route: "/pages/authentication/sign-in",
            component: (
              <MKButton
        variant={isAuthenticated ? "outlined" : "contained"}
        color="info"
        onClick={handleAuthAction}  // Assuming this handles authentication
        size="small"
      >
        {isAuthenticated ? "Sign Out" : "Sign In"}  {/* Button text toggle */}
      </MKButton>
            ),
          }}
          transparent
          relative
        />
      </MKBox>
      <Container sx={{ mt: 6 }}>
        <Grid container item xs={12} flexDirection="column" justifyContent="center" mx="auto">
          <MKBox width={{ xs: "100%", md: "50%", lg: "25%" }} mb={3}>
            <Breadcrumbs routes={breadcrumb} />
          </MKBox>
          <MKTypography variant="h3" mb={1}>
            {title}
          </MKTypography>
          {children}
        </Grid>
      </Container>
      <MKBox mt="auto">
        <CenteredFooter />
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  breadcrumb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
