import React, { useState } from "react";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const FacialRecognition = () => {
  const [idFile, setIdFile] = useState(null);
  const [selfieFile, setSelfieFile] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFileChange = (event, setFile) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!idFile || !selfieFile) {
      setError("Both ID and selfie are required.");
      return;
    }

    setError(null);
    setLoading(true);
    setResult(null);

    const formData = new FormData();
    formData.append("id_image", idFile);
    formData.append("selfie_image", selfieFile);

    try {
      const response = await fetch("http://127.0.0.1:5000/api/diapi/match", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to process the request.");
      }

      const data = await response.json();
      setResult(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MKBox p={3}>
      <MKTypography variant="h4">Facial Recognition</MKTypography>
      <MKTypography variant="body1" mt={2}>
        Upload your ID and a selfie for verification.
      </MKTypography>

      <MKBox mt={3}>
        <MKTypography variant="body2">Upload ID:</MKTypography>
        <Input
          accept="image/*"
          type="file"
          onChange={(e) => handleFileChange(e, setIdFile)}
          inputProps={{ 'aria-label': 'Upload ID' }}
          sx={{ marginTop: "10px", display: "block" }}
        />
      </MKBox>

      <MKBox mt={3}>
        <MKTypography variant="body2">Upload Selfie:</MKTypography>
        <Input
          accept="image/*"
          type="file"
          onChange={(e) => handleFileChange(e, setSelfieFile)}
          inputProps={{ 'aria-label': 'Upload Selfie' }}
          sx={{ marginTop: "10px", display: "block" }}
        />
      </MKBox>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{ marginTop: 3 }}
        startIcon={<CloudUploadIcon />}
        disabled={loading}
        style={{ color: "white" }}
      >
        {loading ? "Processing..." : "Submit for Verification"}
      </Button>

      {error && <MKTypography variant="body2" color="error" mt={2}>{`Error: ${error}`}</MKTypography>}
      {result && (
        <MKBox mt={3}>
          <MKTypography variant="h5">Match Result</MKTypography>
          <MKTypography variant="body2" mt={1}>{JSON.stringify(result, null, 2)}</MKTypography>
        </MKBox>
      )}
    </MKBox>
  );
};

export default FacialRecognition;
