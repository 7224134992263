import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import {
  Container,
  Paper,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import DeleteIcon from "@mui/icons-material/Delete";

// Sample AI Services for each Cloud Provider (With unique colors)
const aiServices = {
  azure: [
    { id: "azure-computer-vision", name: "Computer Vision", color: "#2c6cd8" },
    { id: "azure-nlp", name: "Natural Language Processing", color: "#2c6cd8" },
    { id: "azure-speech", name: "Speech-to-Text", color: "#2c6cd8" },
  ],
  aws: [
    { id: "aws-rekognition", name: "Rekognition", color: "#ff9900" },
    { id: "aws-comprehend", name: "Comprehend", color: "#ff9900" },
    { id: "aws-lex", name: "Lex", color: "#ff9900" },
  ],
  gcp: [
    { id: "gcp-vision", name: "Vision API", color: "#4285F4" },
    { id: "gcp-dialogflow", name: "Dialogflow", color: "#4285F4" },
    { id: "gcp-language", name: "Language API", color: "#4285F4" },
  ],
};

// Additional Services List
const additionalServices = [
  "Multimodal",
  "Audio-Text-to-Text",
  "Image-Text-to-Text",
  "Visual Question Answering",
  "Document Question Answering",
  "Video-Text-to-Text",
  "Any-to-Any",
  "Computer Vision",
  "Depth Estimation",
  "Image Classification",
  "Object Detection",
  "Image Segmentation",
  "Text-to-Image",
  "Image-to-Text",
  "Image-to-Image",
  "Image-to-Video",
  "Unconditional Image Generation",
  "Video Classification",
  "Text-to-Video",
  "Zero-Shot Image Classification",
  "Mask Generation",
  "Zero-Shot Object Detection",
  "Text-to-3D",
  "Image-to-3D",
  "Image Feature Extraction",
  "Keypoint Detection",
  "Natural Language Processing",
  "Text Classification",
  "Token Classification",
  "Table Question Answering",
  "Question Answering",
  "Zero-Shot Classification",
  "Translation",
  "Summarization",
  "Feature Extraction",
  "Text Generation",
  "Text2Text Generation",
  "Fill-Mask",
  "Sentence Similarity",
  "Audio",
  "Text-to-Speech",
  "Text-to-Audio",
  "Automatic Speech Recognition",
  "Audio-to-Audio",
  "Audio Classification",
  "Voice Activity Detection",
  "Tabular",
  "Tabular Classification",
  "Tabular Regression",
  "Time Series Forecasting",
  "Reinforcement Learning",
  "Reinforcement Learning",
  "Robotics",
  "Other",
  "Graph Machine Learning",
];

// Dropzone for Workflow Building
const Dropzone = ({ onDrop, workflows, connectors, onClear }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ["service", "connector"],
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <Paper
      ref={drop}
      style={{
        minHeight: "300px",
        border: "1px dashed black",
        marginTop: "20px",
        padding: "20px",
        position: "relative",
      }}
    >
      <Typography variant="h6">
        Drop your services or connectors here to build workflows
      </Typography>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {workflows.map((workflow) => (
          <Paper
            key={workflow.id}
            style={{
              backgroundColor: workflow.color,
              padding: "10px",
              display: "inline-block",
            }}
          >
            <Typography>{workflow.name}</Typography>
          </Paper>
        ))}
      </div>

      {/* Render Connectors on Drop Area */}
      {connectors.length > 0 && (
        <div>
          {connectors.map((connector, index) => (
            <div
              key={connector.id}
              style={{
                position: "absolute",
                top: connector.top,
                left: connector.left,
                zIndex: 1,
                transform: "translate(-50%, -50%)",
              }}
            >
              {connector.icon}
            </div>
          ))}
        </div>
      )}

      {/* Clear Button */}
      <IconButton
        onClick={onClear}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "#f44336",
          color: "white",
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Paper>
  );
};

// Draggable Service Item (for AI Services)
const DraggableService = ({ service, isSelected, onSelect }) => {
  const [, drag] = useDrag(() => ({
    type: "service",
    item: { id: service.id, name: service.name, color: service.color },
  }));

  return (
    <Paper
      ref={drag}
      onClick={() => onSelect(service.id)} // Select on click
      style={{
        backgroundColor: service.color,
        padding: "10px",
        margin: "10px",
        cursor: "move",
        width: "120px",
        border: isSelected ? "3px solid #000" : "none", // Highlight selected item
        boxShadow: isSelected ? "0px 0px 10px rgba(0,0,0,0.5)" : "none", // Add shadow to selected service
        textAlign: "center",
      }}
    >
      <Typography style={{ color: "white" }}>{service.name}</Typography>
    </Paper>
  );
};

// Draggable Connector Item (icons for arrows or connectors)
const DraggableConnector = ({ icon }) => {
  const [, drag] = useDrag(() => ({
    type: "connector",
    item: { icon },
  }));

  return (
    <Paper
      ref={drag}
      style={{
        padding: "10px",
        margin: "10px",
        cursor: "move",
        backgroundColor: "#ccc",
        display: "inline-block",
        width: "60px",
        textAlign: "center",
        borderRadius: "5px",
        transition: "0.2s",
      }}
    >
      <div style={{ fontSize: "24px" }}>{icon}</div>
    </Paper>
  );
};

const XeloStudio = () => {
  const [workflows, setWorkflows] = useState([]);
  const [connectors, setConnectors] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("azure"); // default to Azure
  const [selectedServiceId, setSelectedServiceId] = useState(null); // State to track selected service
  const [showConnectors, setShowConnectors] = useState(false);
  const [nextTopPosition, setNextTopPosition] = useState(0); // Manage next available vertical position

  // Handle provider selection to filter services
  const handleProviderClick = (provider) => {
    setSelectedProvider(provider); // Set the currently selected provider
  };

  // Drop handler for both services and connectors
  const handleDrop = (item) => {
    let newTopPosition = nextTopPosition;

    if (item.name) {
      // Handle dropping a service
      const newWorkflow = { ...item, id: uuidv4(), top: newTopPosition };
      setWorkflows((prevWorkflows) => [...prevWorkflows, newWorkflow]);
      newTopPosition += 100; // Increment top position for next item (increase as needed for spacing)

      if (workflows.length > 1) {
        // Add a connector after the previous workflow
        const lastWorkflow = workflows[workflows.length - 1];
        const newConnector = {
          id: uuidv4(),
          icon: <ArrowForwardIcon />,
          top: lastWorkflow.top + 60, // Adjust the vertical position of the connector
          left: 100, // Adjust the horizontal position as per needs
        };

        setConnectors((prevConnectors) => [...prevConnectors, newConnector]);
      }
    } else if (item.icon) {
      // Handle dropping a connector
      const newConnector = {
        id: uuidv4(),
        icon: item.icon,
        top: newTopPosition + 60, // Adjust vertical position for connectors
        left: 100, // Adjust horizontal position for connectors
      };
      setConnectors((prevConnectors) => [...prevConnectors, newConnector]);

      newTopPosition += 60; // Increment after the connector
    }

    // Update the next available position
    setNextTopPosition(newTopPosition);
  };

  // Handle service selection
  const handleSelectService = (serviceId) => {
    setSelectedServiceId(serviceId === selectedServiceId ? null : serviceId); // Toggle selection
  };

  // Clear all workflow items
  const handleClearWorkflow = () => {
    setWorkflows([]);
    setConnectors([]);
    setNextTopPosition(0); // Reset vertical position counter
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Main Workflow Area */}
        <div style={{ width: "70%", marginRight: "20px" }}>
          <Typography
            variant="h4"
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            XeloStudio - Workflow Builder
          </Typography>

          {/* Dropzone for Users to Build Their Workflow */}
          <Dropzone
            onDrop={handleDrop}
            workflows={workflows}
            connectors={connectors}
            onClear={handleClearWorkflow}
          />
        </div>

        {/* Right Pane - AI Services, Connectors, and Additional Services */}
        <div
          style={{
            width: "30%",
            position: "sticky",
            top: "20px",
            height: "100vh",
            overflowY: "scroll",
            backgroundColor: "#f7f7f7",
            borderLeft: "1px solid #ddd",
            padding: "20px",
          }}
        >
          {/* Collapsible AI Services Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ai-services-panel-content"
            >
              <Typography variant="h6">AI Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <Button
                  variant="contained"
                  onClick={() => handleProviderClick("azure")}
                  style={{
                    backgroundColor: "#2c6cd8",
                    color: "#fff",
                    marginBottom: "10px",
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  Azure AI
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleProviderClick("aws")}
                  style={{
                    backgroundColor: "#ff9900",
                    color: "#fff",
                    marginBottom: "10px",
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  AWS AI
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleProviderClick("gcp")}
                  style={{
                    backgroundColor: "#4285F4",
                    color: "#fff",
                    marginBottom: "10px",
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "5px",
                    boxShadow: "none",
                  }}
                >
                  GCP AI
                </Button>
                <div>
                  {aiServices[selectedProvider].map((service) => (
                    <DraggableService
                      key={service.id}
                      service={service}
                      isSelected={selectedServiceId === service.id}
                      onSelect={handleSelectService}
                    />
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Collapsible Connectors Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="connectors-panel-content"
            >
              <Typography variant="h6">Connectors</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <DraggableConnector icon={<ArrowForwardIcon />} />
                <DraggableConnector icon={<LineAxisIcon />} />
                <DraggableConnector icon={<LinearScaleIcon />} />
              </div>
            </AccordionDetails>
          </Accordion>

          {/* Collapsible Additional Services Section */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="additional-services-panel-content"
            >
              <Typography variant="h6">Additional Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {additionalServices.map((service) => (
                  <DraggableService
                    key={service}
                    service={{
                      id: `additional-${service}`, // Ensure unique ID for additional services
                      name: service,
                      color: "#4285F4", // Set default color for additional services
                    }}
                    isSelected={false} // Selection logic is not applied here
                    onSelect={() => {}} // No-op function as these items are not selectable
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </DndProvider>
  );
};

export default XeloStudio;
