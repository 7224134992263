import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Importing icons
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import SecurityIcon from "@mui/icons-material/Security";
import SmsIcon from "@mui/icons-material/Sms";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import BlockchainIcon from "@mui/icons-material/AccountBalance";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FacebookIcon from "@mui/icons-material/Facebook";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DocumentVerification from "../DocumentVerification";
import FacialRecognition from "./FacialRecognition";
//import FacialRecognition from "../FacialRecognition"; // Import your FacialRecognition component

const DigitalIdentityVerification = ({ onChildFeatureClick }) => {
  const verificationDetails = [
    {
      label: "Document Verification",
      description: "Upload and verify government-issued IDs for identity validation.",
      icon: <CameraAltIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
      component: <DocumentVerification />, // Directly linking to the DocumentVerification component
    },
    {
      label:"Income Verification",
      description:"Unlock real-time, financial data to understand all aspects of an individual's income for accurate decision-making and a smooth onboarding experience.",
      icon: <CameraAltIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
      component: <DocumentVerification />
    },
    {
      label: "Facial Recognition",
      description: "Use selfies to match ID photos and prevent fraud.",
      icon: <CameraAltIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
      component: <FacialRecognition />, // Add FacialRecognition as the component
    },
    {
      label: "Biometric Authentication",
      description: "Authenticate via fingerprints, iris scans, or facial geometry.",
      icon: <FingerprintIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Knowledge-Based Authentication (KBA)",
      description: "Answer questions based on personal information for verification.",
      icon: <QuestionAnswerIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Two-Factor Authentication (2FA)",
      description: "Receive a one-time code to confirm your identity.",
      icon: <SmsIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Behavioral Biometrics",
      description: "Analyze typing patterns and mouse movements to detect fraud.",
      icon: <SecurityIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Mobile Authentication",
      description: "Use your mobile phone for identity confirmation.",
      icon: <DeviceHubIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Blockchain-Based Verification",
      description: "Secure your identity via decentralized, blockchain technology.",
      icon: <BlockchainIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Liveness Detection",
      description: "Ensure the user is present in real-time to prevent spoofing.",
      icon: <VerifiedUserIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Social Logins",
      description: "Log in and verify your identity using social media accounts.",
      icon: <FacebookIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Digital Identity Wallets",
      description: "Manage and share identity data securely with trusted entities.",
      icon: <WalletIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
  ];

  const [selectedChildFeature, setSelectedChildFeature] = useState(null);

  const handleFeatureClick = (component) => {
    setSelectedChildFeature(component); // Load the clicked feature (sub-component)
  };

  return (
    <MKBox>
      <MKTypography variant="h4" mb={3}>
        Digital Identity Verification
      </MKTypography>

      {/* If a child feature is selected, render it */}
      {selectedChildFeature ? (
        selectedChildFeature // This renders the selected child component
      ) : (
        // Otherwise, show the grid of main verification options
        <Grid container spacing={3}>
          {verificationDetails.map((detail, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  minHeight: 150,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#1976D2", // Blue color
                  color: "white", // White text and icons
                  textAlign: "center",
                  padding: 2,
                  borderRadius: "12px", // Smooth corners
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Slight shadow
                  transition: "transform 0.2s ease-in-out, background-color 0.2s ease",
                  "&:hover": {
                    backgroundColor: "#1565C0", // Darker blue shade
                    transform: "scale(1.05)", // Slight zoom on hover
                  },
                }}
                onClick={() => handleFeatureClick(detail.component)} // Load the specific component when clicked
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {/* Icon for each feature */}
                  {detail.icon}

                  <MKTypography variant="h6" color="white" gutterBottom>
                    {detail.label}
                  </MKTypography>
                  <MKTypography variant="body2" color="white">
                    {detail.description}
                  </MKTypography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </MKBox>
  );
};

export default DigitalIdentityVerification;
