import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Importing icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import PeopleIcon from "@mui/icons-material/People";
import SpeedIcon from "@mui/icons-material/Speed";

const XeloSense = () => {
  const details = [
    {
      label: "Centralized management interface",
      description: "Easily manage and monitor from one interface.",
      icon: <DashboardIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Customizable dashboards",
      description: "Create dashboards tailored to your needs.",
      icon: <BarChartIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Integration with Xelo Services",
      description: "Seamlessly integrate with other Xelo tools.",
      icon: <IntegrationInstructionsIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "User and role management",
      description: "Assign roles and manage users effectively.",
      icon: <PeopleIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
    {
      label: "Performance analytics",
      description: "Gain insights with advanced analytics.",
      icon: <SpeedIcon sx={{ fontSize: "3rem", color: "#fff" }} />,
    },
  ];

  return (
    <MKBox>
      <MKTypography variant="h4" mb={3}>
      XeloSense – Adaptive AI-driven Anomaly Detection
      </MKTypography>

      <Grid container spacing={3}>
        {details.map((detail, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                minHeight: 180,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#1976D2", // Blue color
                color: "white", // White text and icons
                textAlign: "center",
                padding: 2,
                borderRadius: "12px", // Smooth corners
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)", // Slight shadow
                transition: "transform 0.2s ease-in-out, background-color 0.2s ease",
                "&:hover": {
                  backgroundColor: "#1565C0", // Darker blue shade
                  transform: "scale(1.05)", // Slight zoom on hover
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Icon for each feature */}
                {detail.icon}

                <MKTypography variant="h6" color="white" gutterBottom>
                  {detail.label}
                </MKTypography>
                <MKTypography variant="body2" color="white">
                  {detail.description}
                </MKTypography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MKBox>
  );
};

export default XeloSense;
