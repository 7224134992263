import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Button from "@mui/material/Button"; // For the back functionality
import DocumentVerificationIcon from "@mui/icons-material/Description";
import BiometricVerificationIcon from "@mui/icons-material/Fingerprint";
import FraudDetectionIcon from "@mui/icons-material/ReportProblem";
import BlockchainIcon from "@mui/icons-material/Lock";
import RiskAuthenticationIcon from "@mui/icons-material/Security";
import DocumentVerification from "./DocumentVerification"; // Your Document Verification component
import RiskBasedAuthentication from "./RiskBasedAuthentication"; // Example component
import BlockchainIdentityManagement from "./BlockchainIdentityManagement"; // Example component
import FraudDetection from "./FraudDetection"; // Example component
import BiometricVerification from "./BiometricVerification"; // Example component
import DigitalIdentityVerification from "./DigitalIdentityVerification"; // Digital Identity Verification component

const XeloVerify = ({ onFeatureChange }) => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedSubFeature, setSelectedSubFeature] = useState(null); // For handling sub-features like Facial Recognition

  const details = [
    {
      label: "Digital Identity Verification",
      description: "Offer document scanning, eKYC, and anti-spoofing.",
      icon: <DocumentVerificationIcon fontSize="large" color="white" />,
      component: (
        <DigitalIdentityVerification
          onChildFeatureClick={(childFeature) => setSelectedSubFeature(childFeature)} // Pass callback for child click
        />
      ),
    },
    {
      label: "Document Verification",
      description: "Upload documents for verification.",
      icon: <DocumentVerificationIcon fontSize="large" color="white" />,
      component: <DocumentVerification />,
    },
    {
      label: "Biometric Authentication",
      description: "Provide secure, multi-modal biometric systems.",
      icon: <BiometricVerificationIcon fontSize="large" color="white" />,
      component: <BiometricVerification />,
    },
    {
      label: "Blockchain-based Identity Management",
      description: "Decentralized, tamper-proof identity management using blockchain.",
      icon: <BlockchainIcon fontSize="large" color="white" />,
      component: <BlockchainIdentityManagement />,
    },
    {
      label: "Fraud Detection in Transactions",
      description: "Analyze transaction patterns in real-time to detect fraud.",
      icon: <FraudDetectionIcon fontSize="large" color="white" />,
      component: <FraudDetection />,
    },
    {
      label: "Risk-based Authentication (RBA)",
      description: "Dynamic, context-aware verification levels based on user activity.",
      icon: <RiskAuthenticationIcon fontSize="large" color="white" />,
      component: <RiskBasedAuthentication />,
    },
  ];

  const handleCardClick = (component) => {
    setSelectedFeature(component); // Load the clicked feature component
    setSelectedSubFeature(null); // Reset any selected sub-feature
  };

  return (
    <MKBox>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MKTypography variant="h4">
          XeloVerify – Advanced Verification Mechanisms
        </MKTypography>
        {selectedFeature && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSelectedFeature(null)} // Back to the main page
            style={{ color: "white" }}
          >
            Back to XeloVerify
          </Button>
        )}
      </MKBox>

      {/* Display selected feature or the grid of cards */}
      {selectedFeature ? (
        selectedSubFeature ? (
          selectedSubFeature // Render the sub-feature, e.g., Facial Recognition
        ) : (
          selectedFeature // Render the main component (e.g., DigitalIdentityVerification)
        )
      ) : (
        <Grid container spacing={3}>
          {details.map((detail, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  minHeight: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  backgroundColor: "#1976D2", // Main color
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#1565C0", // Darker shade on hover
                  },
                }}
                onClick={() => handleCardClick(detail.component)} // Trigger to load the component
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {detail.icon}

                  <MKTypography variant="h6" mt={2} color="white">
                    {detail.label}
                  </MKTypography>
                  <MKTypography variant="body2" mt={1} color="white">
                    {detail.description}
                  </MKTypography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </MKBox>
  );
};

export default XeloVerify;
